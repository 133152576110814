import dayjs from 'dayjs'

export const timeRangeList = [
  {
    label: '今天',
    value: 'today',
    start_time: dayjs().format('YYYY-MM-DD 00:00:00'),
    end_time: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
  {
    label: '昨天',
    value: 'yesterday',
    start_time: dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'),
    end_time: dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'),
  },
  {
    label: '近7天',
    value: 'week',
    start_time: dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
    end_time: dayjs().format('YYYY-MM-DD 23:59:59'),
  },
  {
    label: '本月',
    value: 'currentMonth',
    start_time: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    end_time: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    label: '上月',
    value: 'lasterMonth',
    start_time: dayjs()
      .add(-1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss'),
    end_time: dayjs()
      .add(-1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    label: '全部',
    value: 'all',
    start_time: '',
    end_time: '',
  },
  {
    label: '自定义',
    value: 'custom',
  },
]

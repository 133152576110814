export const clientTableColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
  },
  {
    title: '推广人员',
    scopedSlots: { customRender: 'promoter' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    title: '账户余额（元）',
    dataIndex: 'balance',
  },
  {
    title: '操作',
    width: '200px',
    scopedSlots: { customRender: 'action' },
  },
]
export const acountTableColumns = [
  {
    title: '时间',
    dataIndex: 'create_time',
  },
  {
    title: '收支类型',
    dataIndex: 'type',
  },
  {
    title: '金额（元）',
    dataIndex: 'amount',
  },
]
